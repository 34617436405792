<div class="container">
	<div class="row">
		<div class="col-md-4">
			<p class="text-md-left">
				<a href="http://www.rossmann.hu/" target="_blank">&copy; Rossmann Magyarország Kft. - www.rossmann.hu</a>
			</p>
		</div>
		<div class="col-md-4">
			<p><i class="icomoon">&#x25c9;</i></p>
		</div>
	</div>
</div>

<style>
	.container {
		position: relative;
	}

	.hero {
		display: flex;
		align-items: center;
		min-height: 8.125rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-image: linear-gradient(0deg, #fff 0%, #e3eff6 100%);

		h1 {
			color: #b50f36;
			line-height: 1.2;
		}
	}
</style>

<main style="padding-top: 0">
	<section class="hero">
		<div class="container">
			<div class="row">
				<div class="col-md-3">
					<p>
						<img src="/assets/img/logo-rossmann-design.png" class="img-fluid" alt="">
					</p>
				</div>
			</div>
		</div>
	</section>
	<section class="landing" style="background-color: #b50f36;">
		<div class="container">
			<div class="row d-flex align-items-center" style="padding-top: 30px; margin: 0">
				<h1 style="color: white">
					Tájékoztatunk, hogy Rossmann Design szolgáltatásunk 2025.01.10. napjával megszűnt. <br/>
					Fedezd fel további ajánlatainkat és lehetőségeinket a weboldalunkon: <br/>
					<a style="text-decoration: none; color: #87CEEB" href="https://shop.rossmann.hu">Rossmann Drogéria Parfüméria</a>
				</h1>
			</div>
			<div class="row" style="padding-top: 30px; padding-bottom: 30px; margin: 0">
				<h1 style="color: white">
					Köszönjük megértésed, és várunk szeretettel webshopunkban! <br/>
					Üdvözlettel: <br/>
					A Rossmann csapata
				</h1>
			</div>
		</div>
	</section>
</main>

<footer></footer>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
